import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { palette } from "../Styles/Palette";

import {
  StyledContainer,
  StyledHeading,
  AddFieldDiv,
  AddFieldButton,
  AddEventButton,
  TextFieldContainer,
  ResponsiveGridItem,
  ResponsiveGrid,
  MainDiv,
  Form,
  ButtonDiv,
  Head,
  ArrowContainer,
} from "../Styles/CreateEventStyles";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, fetchEvent } from "../features/eventSlice";
// import {fetchInstructor } from '../features/instructorSlice';
import { fetchUser } from "../features/userSlice";

const CreateEvent = () => {
  const dispatch = useDispatch();

  const { instDetail, status } = useSelector((state) => state.inst);
  const { users } = useSelector((state) => state.user);

  console.log("instructor", instDetail);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    EventId: "",
    EventName: "",
    OrgName: "",
    OrgId: "",
    SDate: "",
    EDate: "",
    STime: "",
    ETime: "",
    Duration: "",
    AffiliationCategory: "",
    Criteria: "",
    InstCode: "",
    InstName: "",
    CourseDesp: "",
    CourseType: "",
    Delivery: "",
    MeetLink: "",
    EvaluLink: "",
    ExamLink: "",
    MaterialLink: "",
    SponsorLink: "",
    Exam: "",
    EventDesp: "",
    EventSummary: "",
    SchoolId: "",
    SponsorName: "",
    SponsorId: "",
    CourseId: "",
    CourseName: "",
    State1ElectiveCore: "",
    State2ElectiveCore: "",
    CoursePrototype: "",
    Credits: "",
    ClassId: "",
    ClassName: "",
    ExpireAfter: "",
    AltCourseId: "",
    AltCourseName: "",
    CourseAffilation: "",
    EventAccess: "",
    OutlookCal: "",
    CancelPolicy: "",
    OptOut: "",
    Certificate: "",
    Image: "",
    CourseFees: "",
    tags: [],
  });
  const [tagInput, setTagInput] = useState("");
  const [instructors, setInstructors] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);

  const [Field, setField] = useState({
    recipient1: "",
    recipient2: "",
  });

  const handleTagInputKeyDown = (e) => {
    const newTag = tagInput.trim();

    if ((e.key === "Enter" || e.key === ",") && newTag !== "") {
      e.preventDefault();
      setFormData((prev) => ({
        ...prev,
        tags: [...prev.tags, newTag], 
      }));
      setTagInput(""); 
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = formData.tags.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      tags: updatedTags,
    }));
  };

  const handleChange = (e) => {
    setField({
      ...Field,
      [e.target.id]: e.target.value,
    });
  };

  const {
    EventName,
    OrgName,
    SDate,
    EDate,
    STime,
    ETime,
    Duration,
    AffiliationCategory,
    Criteria,
    InstCode,
    InstName,
    Credits,
    CourseDesp,
    CourseType,
    Delivery,
    MeetLink,
    EvaluLink,
    MaterialLink,
    ExamLink,
    SponsorLink,
    Exam,
    EventId,
    OrgId,
    EventDesp,
    EventSummary,
    SchoolId,
    SponsorName,
    SponsorId,
    ClassId,
    ClassName,
    ExpireAfter,
    CourseId,
    CourseName,
    CoursePrototype,
    State1ElectiveCore,
    State2ElectiveCore,
    AltCourseId,
    AltCourseName,
    CourseAffilation,
    EventAccess,
    OutlookCal,
    CancelPolicy,
    OptOut,
    Certificate,
    Image,
    tags,
    CourseFees,
  } = formData;

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (value === "Add Instructor") {
      // Redirect to the '/addInstructor' page

      navigate("/addUser");
    }
  };

  const saveField = (e) => {
    setDynamicFields([
      ...dynamicFields,
      { label: Field.recipient1, value: "", type: Field.recipient2 },
    ]);
  };

  useEffect(() => {
    // dispatch(fetchInstructor());
    dispatch(fetchUser());
  }, []);

  const handleRemoveField = (index) => {
    const updatedFields = [...dynamicFields];
    updatedFields.splice(index, 1);
    setDynamicFields(updatedFields);
  };

  const handleDynamicFieldChange = (index, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index].value = value;
    setDynamicFields(updatedFields);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("first", formData);
  //   dispatch(createEvent(formData));
  //   dispatch(fetchEvent());
  //   navigate("/event");
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const requiredFields = [
      'EventId',
      'EventName',
      'SDate',
      'EDate',
      'STime',
      'ETime',
      'tags',
      'CourseFees',
      'Duration',
      'CourseType',
      'InstName',
      'MeetLink',
      'OutlookCal',
      'Duration',
    ];
  
    const missingFields = requiredFields.filter(
      (field) => !formData[field]
    );
  
    if (missingFields.length > 0) {
      alert(`Please fill out the following required fields: ${missingFields.join(', ')}`);
      return;
    }
  
    dispatch(createEvent(formData));
    dispatch(fetchEvent());
    navigate('/event');
  };

  return (
    <StyledContainer>
      <Head>
        <ArrowContainer>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{ color: palette.primary }} />
          </IconButton>
        </ArrowContainer>
        <StyledHeading>Add Event Details</StyledHeading>
      </Head>
      <Form>
        <MainDiv>
          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Event ID"
                variant="outlined"
                name="EventId"
                value={formData.EventId}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Name"
                variant="outlined"
                name="EventName"
                value={formData.EventName}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Organization ID"
                variant="outlined"
                name="OrgId"
                value={formData.OrgId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Organization Name"
                variant="outlined"
                name="OrgName"
                value={formData.OrgName}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <Grid item sx={{ mb: 3 }}>
            <TextField
              label="Event Description"
              variant="outlined"
              name="EventDesp"
              multiline
              rows={4}
              value={formData.EventDesp}
              onChange={onChangeHandler}
              fullWidth
            />
          </Grid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Event Summary"
                variant="outlined"
                name="EventSummary"
                value={formData.EventSummary}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="School ID Number"
                variant="outlined"
                name="SchoolId"
                value={formData.SchoolId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                className="form-control"
                name="SDate"
                value={formData.SDate}
                onChange={onChangeHandler}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: "numeric",
                }}
              />
            </ResponsiveGridItem>
            <Grid sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                className="form-control"
                name="EDate"
                value={formData.EDate}
                onChange={onChangeHandler}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Start Time"
                variant="outlined"
                type="time"
                name="STime"
                value={formData.STime}
                onChange={onChangeHandler}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem
              item
              sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="End Time"
                variant="outlined"
                name="ETime"
                type="time"
                value={formData.ETime}
                onChange={onChangeHandler}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Tags"
                variant="outlined"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)} 
                onKeyDown={handleTagInputKeyDown}
                fullWidth
                required
                helperText="Press 'Enter' or ',' to add a tag"
              />

              <div>
                {formData.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleRemoveTag(index)} 
                  />
                ))}
              </div>
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Course Fees"
                variant="outlined"
                name="CourseFees"
                value={formData.CourseFees}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Sponsor Name"
                variant="outlined"
                name="SponsorName"
                value={formData.SponsorName}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Sponsor ID"
                variant="outlined"
                name="SponsorId"
                value={formData.SponsorId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Class Name"
                variant="outlined"
                name="ClassName"
                value={formData.ClassName}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Class ID"
                variant="outlined"
                name="ClassId"
                value={formData.ClassId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Course Prototype"
                variant="outlined"
                name="CoursePrototype"
                value={formData.CoursePrototype}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Expire After"
                variant="outlined"
                name="ExpireAfter"
                value={formData.ExpireAfter}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="State1 Elective Core"
                variant="outlined"
                name="State1ElectiveCore"
                value={formData.State1ElectiveCore}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="State2 Elective Core"
                variant="outlined"
                name="State2ElectiveCore"
                value={formData.State2ElectiveCore}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Duration"
                variant="outlined"
                name="Duration"
                value={formData.Duration}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Member Type"
                name="AffiliationCategory"
                value={formData.AffiliationCategory}
                onChange={onChangeHandler}
                defaultValue="none"
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Criteria"
                select
                name="Criteria"
                value={formData.Criteria}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
              >
                <MenuItem value="none" disabled>
                  Select Criteria
                </MenuItem>
                <MenuItem value="below60">Below 60%</MenuItem>
                <MenuItem value="above60">Above 60%</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Credits"
                variant="outlined"
                name="Credits"
                value={formData.Credits}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Type"
                select
                name="CourseType"
                value={formData.CourseType}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Course Type
                </MenuItem>
                <MenuItem value="Workshop">Workshop</MenuItem>
                <MenuItem value="Seminar">Seminar</MenuItem>
                <MenuItem value="Lecture">Lecture</MenuItem>
                <MenuItem value="Hands-on Lab">Hands-on Lab</MenuItem>
                <MenuItem value="Webinar">Webinar</MenuItem>
                <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                <MenuItem value="Training Program">Training Program</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Delivery Method"
                select
                name="Delivery"
                value={formData.Delivery}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                // required
              >
                <MenuItem value="none" disabled>
                  Select Method
                </MenuItem>
                <MenuItem value="In-person">In-person</MenuItem>
                <MenuItem value="Live Online">Live Online</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
                <MenuItem value="Self-paced Online">Self-paced Online</MenuItem>
                <MenuItem value="Interactive Webinar">
                  Interactive Webinar
                </MenuItem>
                <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                <MenuItem value="Video-based Course">
                  Video-based Course
                </MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Instructor Code"
                variant="outlined"
                name="InstCode"
                value={formData.InstCode}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Instructor Name"
                select
                value={formData.InstName}
                name="InstName"
                onChange={onChangeHandler}
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Instructor
                </MenuItem>
                {users
                  ?.filter((instructor) => instructor.role === "Instructor") 
                  .map((instructor) => (
                    <MenuItem
                      key={instructor?._id}
                      value={instructor?.username}
                    >
                      {instructor?.username}
                    </MenuItem>
                  ))}

                {/* <MenuItem value="Add Instructor">Add Instructor</MenuItem> */} 
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Affiliation"
                select
                name="CourseAffilation"
                value={formData.CourseAffilation}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
              >
                <MenuItem value="none" disabled>
                  Select Event Affiliation
                </MenuItem>
                <MenuItem value="Elective">Elective</MenuItem>
                <MenuItem value="Compulsory">Compulsory</MenuItem>
                <MenuItem value="Core Curriculum">Core Curriculum</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Accessibility"
                select
                name="EventAccess"
                value={formData.EventAccess}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
              >
                <MenuItem value="none" disabled>
                  Select Accessibility Type
                </MenuItem>
                <MenuItem value="Restricted Access">Restricted Access</MenuItem>
                <MenuItem value="Open to All">Open to All</MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Sponsor Link"
                variant="outlined"
                name="SponsorLink"
                value={formData.SponsorLink}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Exam Link"
                variant="outlined"
                name="ExamLink"
                value={formData.ExamLink}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Meet Link"
                variant="outlined"
                name="MeetLink"
                value={formData.MeetLink}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Evaluation Link"
                variant="outlined"
                name="EvaluLink"
                value={formData.EvaluLink}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Material Link"
                variant="outlined"
                name="MaterialLink"
                value={formData.MaterialLink}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Outlook Calander Integration"
                select
                name="OutlookCal"
                value={formData.OutlookCal}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Survey OptOut"
                select
                name="OptOut"
                value={formData.OptOut}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Certificate"
                select
                name="Certificate"
                value={formData.Certificate}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                // required
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <Grid item sx={{ mb: 3 }}>
            <TextField
              label="Cancellation Policy"
              variant="outlined"
              name="CancelPolicy"
              helperText="*Terms and conditions governing the cancellation of the event, including any applicable fees, penalties, or procedures"
              multiline
              rows={4}
              value={formData.CancelPolicy}
              onChange={onChangeHandler}
              fullWidth
            />
          </Grid>

          {dynamicFields.map((field, index) => (
            <div key={index} className="mb-3">
              <label className="form-label">{field.label}</label>
              <input
                type={field.type}
                className="form-control"
                value={field.value}
                onChange={(e) =>
                  handleDynamicFieldChange(index, e.target.value)
                }
                required
              />
              <IconButton onClick={() => handleRemoveField(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}

          <AddFieldDiv>
            <AddFieldButton
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Add Field
            </AddFieldButton>
          </AddFieldDiv>

          <div className="mb-3">
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add New Field
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <Grid>
                          <TextField
                            label="Field Name"
                            variant="outlined"
                            id="recipient1"
                            onChange={handleChange}
                            required
                            fullWidth
                          />
                        </Grid>
                      </div>
                      <div className="form-group">
                        {/* <FormControl fullWidth required>
                      <InputLabel id="dataType">Data Type</InputLabel>
                      <Select
                        labelId="dataType"
                        id="dataType"
                        onChange={handleChange}
                        placeholder="select"
                        displayEmpty
                      >
                        <MenuItem value="none" disabled>
                          Select Data Type
                        </MenuItem>
                        <MenuItem value="String">String</MenuItem>
                        <MenuItem value="Number">Number</MenuItem>
                      </Select>
                    </FormControl> */}
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={saveField}
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainDiv>
        <ButtonDiv>
          <AddEventButton type="submit" onClick={handleSubmit}>
            Add Event
          </AddEventButton>
        </ButtonDiv>
      </Form>
    </StyledContainer>
  );
};

export default CreateEvent;
