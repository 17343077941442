import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import { palette } from "../Styles/Palette";

import {
  StyledContainer,
  StyledHeading,
  AddEventButton,
  ResponsiveGridItem,
  ResponsiveGrid,
  MainDiv,
  Form,
  ButtonDiv,
  ArrowContainer,
  Head,
} from "../Styles/CreateEventStyles";
import {
  TextField,
  MenuItem,
  Grid,
  Chip
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateEvent, fetchEventDetails } from "../features/eventSlice";

const UpdateEvent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { events, status } = useSelector((state) => state.app); // Assuming your event reducer is named 'event'
  console.log("hello", events);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    EventId: "",
    EventName: "",
    OrgName: "",
    OrgId: "",
    SDate: "",
    EDate: "",
    STime: "",
    ETime: "",
    Duration: "",
    AffiliationCategory: "",
    Criteria: "",
    InstCode: "",
    InstName: "",
    CourseDesp: "",
    CourseType: "",
    Delivery: "",
    MeetLink: "",
    EvaluLink: "",
    ExamLink:"",
    SponsorLink:"",
    MaterialLink: "",
    Exam: "",
    EventDesp: "",
    EventSummary:"",
    SchoolId: "",
    SponsorName: "",
    SponsorId: "",
    ClassId:"",
    CoursePrototype: "",
    ClassName:"",
    ExpireAfter:"",
    CourseId: "",
    CourseName: "",
    Credits:"",
    AltCourseId: "",
    AltCourseName: "",
    CourseAffilation: "",
    EventAccess: "",
    OutlookCal: "",
    CourseFees: "",
    CancelPolicy: "",
    OptOut: "",
    Certificate: "",
    tagsInput: "",
    updatedBy: " ",
    tags: [],
  });
  const [instructors, setInstructors] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [tagInput, setTagInput] = useState("");


  const {
    EventName,
    OrgName,
    SDate,
    EDate,
    STime,
    ETime,
    Duration,
    AffiliationCategory,
    Criteria,
    InstCode,
    InstName,
    CourseDesp,
    CourseType,
    State1ElectiveCore,
    State2ElectiveCore,
    Delivery,
    MeetLink,
    EvaluLink,
    ExamLink,
    MaterialLink,
    SponsorLink,
    Exam,
    EventId,
    OrgId,
    EventDesp,
    EventSummary,
    SchoolId,
    SponsorName,
    SponsorId,
    ClassId,
    CoursePrototype,
    ClassName,
    CourseId,
    CourseName,
    Credits,
    AltCourseId,
    AltCourseName,
    CourseAffilation,
    EventAccess,
    OutlookCal,
    Tags,
    CancelPolicy,
    OptOut,
    Certificate,
    CourseFees,
    updatedBy,
  } = formData;


  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(fetchEventDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    console.log("Events:", events);
    if (events && events.SDate && events.EDate) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        _id: events._id,
        EventName: events.EventName,
        OrgName: events.OrgName,
        SDate: events.SDate,
        EDate: events.EDate,
        STime: events.STime,
        ETime: events.ETime,
        Duration: events.Duration,
        AffiliationCategory: events.AffiliationCategory,
        Criteria: events.Criteria,
        Credits:events.Credits,
        InstCode: events.InstCode,
        InstName: events.InstName,
        CourseDesp: events.CourseDesp,
        CourseType: events.CourseType,
        Delivery: events.Delivery,
        MeetLink: events.MeetLink,
        EvaluLink: events.EvaluLink,
        ExamLink: events.ExamLink,
        MaterialLink: events.MaterialLink,
        Exam: events.Exam,
        EventId: events.EventId,
        OrgId: events.OrgId,
        EventDesp: events.EventDesp,
        EventSummary: events.EventSummary,
        SchoolId: events.SchoolId,
        SponsorName: events.SponsorName,
        SponsorId: events.SponsorId,
        SponsorLink: events.SponsorLink,
        ClassId:events.ClassId,
        ClassName:events.ClassName,
        CourseId: events.CourseId,
        CourseName: events.CourseName,
        ExpireAfter: events.ExpireAfter,
        State1ElectiveCore:events.State1ElectiveCore,
        State2ElectiveCore:events.State2ElectiveCore,
        CoursePrototype:events.CoursePrototype,
        AltCourseId: events.AltCourseId,
        AltCourseName: events.AltCourseName,
        CourseAffilation: events.CourseAffilation,
        EventAccess: events.EventAccess,
        OutlookCal: events.OutlookCal,
        tags: events.tags || [],
        CourseFees : events.CourseFees,
        CancelPolicy: events.CancelPolicy,
        OptOut: events.OptOut,
        Certificate: events.Certificate,
        updatedBy: localStorage.getItem("myKey"),
      }));
    }
  }, [events, id, dispatch]);

  const Update = (e) => {
    e.preventDefault();
    dispatch(updateEvent(formData));
    navigate('/event');
  };

  useEffect(() => {
    console.log("FormData:", formData);
  }, [formData]);

  const handleRemoveField = (index) => {
    const updatedFields = [...dynamicFields];
    updatedFields.splice(index, 1);
    setDynamicFields(updatedFields);
  };

  const handleTagInputKeyDown = (e) => {
    const newTag = tagInput.trim();

    if ((e.key === "Enter" || e.key === ",") && newTag !== "") {
      e.preventDefault();
      setFormData((prev) => ({
        ...prev,
        tags: [...prev.tags, newTag], 
      }));
      setTagInput(""); 
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = formData.tags.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      tags: updatedTags,
    }));
  };

  return (
    <StyledContainer>
      <Head>
        <ArrowContainer>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{ color: palette.primary }} />
          </IconButton>
        </ArrowContainer>
        <StyledHeading>Update Event Details</StyledHeading>
      </Head>
      <Form>
        <MainDiv>
          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Event Manager ID"
                variant="outlined"
                name="EventId"
                value={formData.EventId}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Name"
                variant="outlined"
                name="EventName"
                value={formData.EventName}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Organization ID"
                variant="outlined"
                name="OrgId"
                value={formData.OrgId}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Organization Name"
                // variant="outlined"
                name="OrgName"
                value={formData.OrgName}
                onChange={onChangeHandler}
                defaultValue="none"
                // required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <Grid item sx={{ mb: 3 }}>
            <TextField
              label="Event Description"
              variant="outlined"
              name="EventDesp"
              multiline
              rows={4}
              value={formData.EventDesp}
              onChange={onChangeHandler}
              fullWidth
            />
          </Grid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Event Summary"
                variant="outlined"
                name="EventSummary"
                value={formData.EventSummary}
                onChange={onChangeHandler}
                // required
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="School ID Number"
                variant="outlined"
                name="SchoolId"
                value={formData.SchoolId}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                id="SDate"
                label="Start Date"
                type="date"
                className="form-control"
                name="SDate"
                value={formData.SDate}
                onChange={onChangeHandler}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: "numeric",
                }}
              />
            </ResponsiveGridItem>
            <Grid sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                id="EDate"
                label="End Date"
                type="date"
                className="form-control"
                name="EDate"
                value={formData.EDate}
                onChange={onChangeHandler}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Start Time"
                variant="outlined"
                name="STime"
                type="time"
                value={formData.STime}
                onChange={onChangeHandler}
                required
                // placeholder="hh:mm AM/PM"
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem
              item
              sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="End Time"
                variant="outlined"
                name="ETime"
                type="time"
                value={formData.ETime}
                onChange={onChangeHandler}
                required
                placeholder="hh:mm AM/PM"
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Tags"
                variant="outlined"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)} // Update input state
                onKeyDown={handleTagInputKeyDown} // Handle keydown for adding tags
                fullWidth
                helperText="Press 'Enter' or ',' to add a tag"
              />

              {/* Display existing tags */}
              <div>
                {formData.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleRemoveTag(index)} // Allow tag removal
                  />
                ))}
              </div>
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Course Fees"
                variant="outlined"
                name="CourseFees"
                value={formData.CourseFees}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Sponsor Name"
                variant="outlined"
                name="SponsorName"
                value={formData.SponsorName}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Sponsor ID"
                variant="outlined"
                name="SponsorId"
                value={formData.SponsorId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Class Name"
                variant="outlined"
                name="ClassName"
                value={formData.ClassName}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Class ID"
                variant="outlined"
                name="ClassId"
                value={formData.ClassId}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="Course Prototype"
                variant="outlined"
                name="CoursePrototype"
                value={formData.CoursePrototype}
                onChange={onChangeHandler}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Expire After"
                variant="outlined"
                name="ExpireAfter"
                value={formData.ExpireAfter}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem
              item
              sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}
            >
              <TextField
                label="State1 Elective Core"
                variant="outlined"
                name="State1ElectiveCore"
                value={formData.State1ElectiveCore}
                onChange={onChangeHandler}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="State2 Elective Core"
                variant="outlined"
                name="State2ElectiveCore"
                value={formData.State2ElectiveCore}
                onChange={onChangeHandler}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Duration"
                variant="outlined"
                name="Duration"
                value={formData.Duration}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Member Type"
                name="AffiliationCategory"
                value={formData.AffiliationCategory}
                onChange={onChangeHandler}
                defaultValue="none"
                fullWidth
                required
              />               
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Criteria"
                select
                name="Criteria"
                value={formData.Criteria}
                onChange={onChangeHandler}
                placeholder="select"
                // displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Criteria
                </MenuItem>
                <MenuItem value="Below 60%">Below 60%</MenuItem>
                <MenuItem value="Above 60%">Above 60%</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Credits"
                variant="outlined"
                name="Credits"
                value={formData.Credits}
                onChange={onChangeHandler}
                // required
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Type"
                select
                name="CourseType"
                value={formData.CourseType}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Course Type
                </MenuItem>
                <MenuItem value="Workshop">Workshop</MenuItem>
                <MenuItem value="Seminar">Seminar</MenuItem>
                <MenuItem value="Lecture">Lecture</MenuItem>
                <MenuItem value="Hands-on Lab">Hands-on Lab</MenuItem>
                <MenuItem value="Webinar">Webinar</MenuItem>
                <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                <MenuItem value="Training Program">Training Program</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Delivery Method"
                select
                name="Delivery"
                value={formData.Delivery}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Method
                </MenuItem>
                <MenuItem value="In-Person">In-Person</MenuItem>
                <MenuItem value="Virtual">Virtual</MenuItem>
                <MenuItem value="Live Online">Live Online</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
                <MenuItem value="Self-paced Online">Self-paced Online</MenuItem>
                <MenuItem value="Interactive Webinar">
                  Interactive Webinar
                </MenuItem>
                <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                <MenuItem value="Video-based Course">
                  Video-based Course
                </MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Instructor Code"
                variant="outlined"
                name="InstCode"
                value={formData.InstCode}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Instructor Name"
                select
                value={formData.InstName}
                name="InstName"
                onChange={onChangeHandler}
                displayEmpty
                fullWidth
                required
              >
               <MenuItem value="none" disabled>
                  Select Instructor
                </MenuItem>
                {users
                  ?.filter((instructor) => instructor.role === "Instructor") // Filter based on role
                  .map((instructor) => (
                    <MenuItem
                      key={instructor?._id}
                      value={instructor?.username}
                    >
                      {instructor?.username}
                    </MenuItem>
                  ))}

                <MenuItem value="Add Instructor">Add Instructor</MenuItem>
  </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Event Affiliation"
                select
                name="CourseAffilation"
                value={formData.CourseAffilation}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select Course Affiliation
                </MenuItem>
                <MenuItem value="Elective">Elective</MenuItem>
                <MenuItem value="Compulsory">Compulsory</MenuItem>
                <MenuItem value="Core Curriculum">Core Curriculum</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
          <TextField
            label="Event Accessibility"
            select
            name="EventAccess"
            value={formData.EventAccess}
            onChange={onChangeHandler}
            placeholder="select"
            displayEmpty
            fullWidth
            // required
          >
            <MenuItem value="none" disabled>
              Select Accessibility Type
            </MenuItem>
            <MenuItem value="Restricted Access">Restricted Access</MenuItem>
            <MenuItem value="Open to All">Open to All</MenuItem>
          </TextField>
        </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
        <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
          <TextField
            label="Sponsor Link"
            variant="outlined"
            name="SponsorLink"
            value={formData.SponsorLink}
            onChange={onChangeHandler}
            required
            fullWidth
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
          <TextField
            label="Exam Link"
            variant="outlined"
            name="ExamLink"
            value={formData.ExamLink}
            onChange={onChangeHandler}
            fullWidth
          />
        </ResponsiveGridItem>
      </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Meet Link"
                variant="outlined"
                name="MeetLink"
                value={formData.MeetLink}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>
            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Evaluation Link"
                variant="outlined"
                name="EvaluLink"
                value={formData.EvaluLink}
                onChange={onChangeHandler}
                fullWidth
              />
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Material Link"
                variant="outlined"
                name="MaterialLink"
                value={formData.MaterialLink}
                onChange={onChangeHandler}
                required
                fullWidth
              />
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Outlook Calander Integration"
                select
                name="OutlookCal"
                value={formData.OutlookCal}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <ResponsiveGrid>
            <ResponsiveGridItem sx={{ mb: 3, mr: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Survey OptOut"
                select
                name="OptOut"
                value={formData.OptOut}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>

            <ResponsiveGridItem sx={{ mb: 3, ml: { xs: 0, md: 1 }, flex: 1 }}>
              <TextField
                label="Certificate"
                select
                name="Certificate"
                value={formData.Certificate}
                onChange={onChangeHandler}
                placeholder="select"
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </ResponsiveGridItem>
          </ResponsiveGrid>

          <Grid item sx={{ mb: 3 }}>
            <TextField
              label="Cancellation Policy"
              variant="outlined"
              name="CancelPolicy"
              helperText="*Terms and conditions governing the cancellation of the event, including any applicable fees, penalties, or procedures"
              multiline
              rows={4}
              value={formData.CancelPolicy}
              onChange={onChangeHandler}
              fullWidth
            />
          </Grid>
          <ButtonDiv>
            <AddEventButton type="submit" onClick={Update}>
              Save Changes
            </AddEventButton>
          </ButtonDiv>
        </MainDiv>
      </Form>
    </StyledContainer>
  );
};

export default UpdateEvent;
