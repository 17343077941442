import React from "react";
import TableUtilityLib from "../Utilities/TableUtilityLib";
const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
  },
  {
    name: "Year",
    selector: (row) => row.year,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
];
export default function DumyTableLearn() {
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };
  return (
    <div>
      <TableUtilityLib
        title="Movie List"
        columns={columns}
        data={data}
        onSelectedRowsChange={handleChange}
      />
    </div>
  );
}
