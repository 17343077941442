import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { IconButton, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StyledLabel, Text, AddButton } from "../Styles/EventStyles";

const ExcelTable = ({ onChildValueChange }) => {
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("handleFileChange");
    Papa.parse(file, {
      header: true, // If your CSV has headers
      complete: (result) => {
        console.log(result, "csv data");
        // console.log("Parsed CSV data:", result.data);
        axios
          .post(
            `${process.env.REACT_APP_URL}/api/eventManager/csvcreateEvent`,
            result.data
          )
          .then((axiosResult) => {
            console.log("Parsed CSV data:", result.data);

            setData(result.data);
            onChildValueChange(data);
            window.alert("CSV imported successfully!");
            setOpenDialog(false);
          })

          .catch((err) => console.log(err));
      },
    });
  };
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleDownloadTemplate = () => {
    const headers = [
      'EventId',
    'EventName',
    'OrgId',
    'OrgName',
    'EventDesp',
    'EventSummary',
    'SchoolId',
    'SDate',
    'EDate',
    'STime',
    'ETime',
    'tagInput',
    'CourseFees',
    'SponsorName',
    'SponsorId',
    'ClassName',
    'ClassId',
    'CoursePrototype',
    'ExpireAfter',
    'State1ElectiveCore',
    'State2ElectiveCore',
    'Duration',
    'AffiliationCategory',
    'Criteria',
    'Credits',
    'CourseType',
    'Delivery',
    'InstCode',
    'InstName',
    'CourseAffiliation',
    'EventAccess',
    'SponsorLink',
    'ExamLink',
    'MeetLink',
    'EvaluLink',
    'MaterialLink',
    'OutlookCal',
    'OptOut',
    'Certificate',
    'CancelPolicy',
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Tooltip title="Click here to import csv file">
        <AddButton onClick={handleOpenDialog}>Import</AddButton>
      </Tooltip>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {/* <DialogTitle>CSV Options</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text>Generate spreadsheet template for adding data</Text>
          <Button
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownloadTemplate}
          >
            Download Template
          </Button>
          <hr style={{ width: "100%", margin: "10px 0" }} />
          <Text>Import spreadsheet template form your device</Text>

          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={() => document.getElementById("csv-input").click()}
          >
            Import File
          </Button>
          <input
            className="hidden"
            id="csv-input"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExcelTable;
