import styled from "styled-components";
import tw from "twin.macro";
import { palette, theme } from "../Palette";
// import {  Typography} from '@mui/material';

export const StyledFormContainer = styled.div`
  ${tw` top-0 left-0 w-full h-full flex flex-col justify-center items-center`}
  background: ${palette.newColor};
`;

export const StyledForm = styled.form`
  ${tw`w-3/4 mx-auto p-8 pt-0 rounded-lg bg-white shadow-md mx-auto mb-4 my-8 mt-24`}
`;

export const StyledBackButton = styled.button`
  ${tw` absolute top-10 bg-transparent border-none cursor-pointer`}
`;

export const StyledInputContainer = styled.div`
  ${tw`flex flex-col lg:flex-row`}
`;

export const StyledInputWrapper = styled.div`
  ${tw`mx-3 mb-3`}
  flex: 1;
`;

export const StyledLabel = styled.label`
  ${tw` block mb-2 `}//   display: block;
//   margin-bottom: 5px;
`;

export const StyledInput = styled.input`
  ${tw` w-full mt-1 border-2 rounded-md h-10 px-2 `}
  border-color: ${palette.primary};
`;

export const StyledSubmitButton = styled.button`
  ${tw`py-2 px-4 rounded-md mb-4 text-white border-none cursor-pointer `}
  background: ${palette.primary};
`;

export const StyledImg = styled.img`
  ${tw`h-1/4 w-1/4 cursor-pointer rounded-lg `}
  border: 2px solid #036ACB;
`;

/////////////////////////

//   export const TypographyStyle = styled(Typography)`
//   font-weight: 18px;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   `;

// export const StyledForm = styled.form`
//   padding: 20px;
//   border: 2px solid #ccc;
//   border-radius: 8px;
// `;

// export const StyledInputContainer = styled.div`
//   display: flex;
//   margin-bottom: 15px;
// `;

// export const StyledInputWrapper = styled.div`
//   flex: 1;
//   margin-left: 20px;
// `;

// export const StyledLabel = styled.label`
//   margin-bottom: 5px;
// `;

// export const StyledSubmitButton = styled(Button)`
//   width: 20%;
//   padding: 10px;
//   border-radius: 5px;
//   margin-top: 20px;
//   background: #FF9900;
//   color: #fff;
//   border: none;
//   cursor: pointer;
// `;

// export const TextFieldContainer = styled.div`
//   margin-bottom: 15px;
//   // Add more CSS styles as needed
// `;
