import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import Logo_5 from "../Images/Logo_5.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Navbar,
  NavLogo,
  NavTitle,
  ProfileDiv,
  ProfileButton,
  ProfileSection,
} from "../Styles/NavbarStyles";
import {
  logInUser,
  logOutUser,
} from "../features/authentication/AuthenticationSlice";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
function EventNavbar() {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((userCred) => {
  //     if (userCred) {
  //       console.log("userCred ds" ,userCred);
  //       userCred.getIdToken().then((token) => {
  //        dispatch(logInUser(token)).then( (userData)=>{
  //         console.log("userData dd" , userData)
  //         if (userData?.payload?.statusCode === 200) {

  //           navigate("/event");
  //         } else {
  //           alert(userData?.payload?.message)
  //           navigate("/");
  //         }  })

  //       });
  //     }
  //   });
  // }, []);

  const logout = async () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("userDetails");
        dispatch(logOutUser());
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };
  const handleUserDetailsClick = () => {
    // Navigate to the user details page
    navigate("/user-details");
  };

  const handleLogout = () => {
    // Clear the user's authentication state
    localStorage.removeItem("email");
    navigate("/"); // Redirect the user to the login page or any other desired route
  };

  return (
    <Navbar style={{ position: "fixed", zIndex: "100" }}>
      <NavLogo src={Logo_5} alt="png" onClick={() => navigate("/event")} />
      <NavTitle>Event Hub</NavTitle>
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <ProfileSection>
          <Avatar
            src={user?.user?.userid?.picture}
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "42px",
            }}
            onClick={toggleMenu}
          />
          {/* Avatar  */}
          <div style={{ color: "white" }}>{user?.user?.userid?.username} </div>
        </ProfileSection>
        {isMenuOpen ? (
          <KeyboardArrowUpIcon
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "32px",
            }}
            onClick={toggleMenu}
          />
        ) : (
          <KeyboardArrowDownIcon
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "32px",
            }}
            onClick={toggleMenu}
          />
        )}
        {isMenuOpen && (
          <ProfileDiv>
            <ProfileButton onClick={() => navigate("/profile")}>
              Profile
            </ProfileButton>
            <ProfileButton onClick={logout}>Logout</ProfileButton>
          </ProfileDiv>
        )}
      </div>
    </Navbar>
  );
}

export default EventNavbar;
