import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../Styles/Palette";

import {
  Form,
  StyledDiv,
  Title,
  Contentwrapper,
  MainPage,
  ArrowContainer,
  TitleContainer,
} from "../Styles/UserStyles/AddUserStyles";
import { TextField, Grid, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { createUser, fetchUser } from "../features/userSlice";

const AddUserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [UserData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    // password: "",
  });

  const handleChange = (e) => {
    setUserData({ ...UserData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("first", UserData);
    const userData = await dispatch(createUser(UserData));
    alert(userData.payload.message);
    dispatch(fetchUser());
    navigate("/event");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TitleContainer>
        <ArrowContainer>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{ color: palette.primary }} />
          </IconButton>
        </ArrowContainer>
        <Title>Add User Details</Title>
      </TitleContainer>
      <MainPage>
        <StyledDiv>
          <Contentwrapper>
            <Grid sx={{ mb: 3 }}>
              <TextField
                label="First Name"
                variant="outlined"
                name="firstname"
                value={UserData.firstname}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

            <Grid sx={{ mb: 3 }}>
              <TextField
                label="Last Name"
                variant="outlined"
                name="lastname"
                value={UserData.lastname}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

            <Grid sx={{ mb: 3 }}>
              <TextField
                label="Email Address"
                variant="outlined"
                name="email"
                value={UserData.email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

            <Grid sx={{ mb: 3 }}>

              <TextField
                label="Role"
                select
                name="role"
                value={UserData.role}
                onChange={handleChange}
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                <MenuItem value="Instructor">Instructor</MenuItem>
                <MenuItem value="Learner">Learner</MenuItem>
              </TextField>

            </Grid>
            
          </Contentwrapper>
        </StyledDiv>
        <Grid sx={{ mb: 3 }}>
          <button type="submit" className="btn btn-primary" fullWidth>
            Add User
          </button>
        </Grid>
      </MainPage>
    </Form>
  );
};

export default AddUserDetails;
