import React, { useEffect, useState } from "react";
import Profile from "../Images/Profile.png";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../Styles/Palette";
import { useNavigate } from "react-router-dom";
import {
  StyledFormContainer,
  StyledForm,
  StyledBackButton,
  StyledInputContainer,
  StyledInputWrapper,
  StyledLabel,
  StyledInput,
  StyledSubmitButton,
  StyledImg,
  StyledInputWrapperRight,
} from "../Styles/My Profile/MyProfileStyles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../features/userSlice";
import { Update } from "@material-ui/icons";

const ProfileForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  console.log(user, "user in profule");
  const [formData, setFormData] = useState({
    username: user?.user?.userid?.username,
    email: user?.user?.userid?.email,
    phoneNo: user?.user?.userid?.phoneNo,
    address1: user?.user?.address1,
    address2: user?.user?.address2,
    state: user?.user?.state,
    // pincode: user?.user?.pincode,
    city: user?.user?.city,
    companyName: user?.user?.username,
    picture: user?.user?.userid?.picture ?? Profile,
  });

  const handleGoBack = () => {
    window.history.back();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, picture: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can add your submit logic here, like sending the form data to a server
    const profileData = await dispatch(updateUser(formData));
    console.log(profileData);
    if (profileData?.payload != null) {
      // console.log("undr")
      alert("Profile Updated Successfully");
    } else {
      alert("Error During Updating");
    }
  };

  return (
    <StyledFormContainer>
      <StyledForm >
        <div>
          <IconButton
            onClick={handleGoBack}
            style={{
              top: 0,
              left: -30,
              color: palette.primary,
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </div>

        <StyledInputContainer marginTop="20px">
          <StyledInputWrapper marginLeft="20px">
            <StyledLabel htmlFor="profile-pic-input"></StyledLabel>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="profile-pic-input"
            />
            <StyledLabel htmlFor="profile-pic-input">
              <StyledImg alt="Profile Pic" src={formData.picture} />
            </StyledLabel>
          </StyledInputWrapper>
          <StyledInputWrapper style={{ marginTop: "50px" }}>
            <label htmlFor="name">Name</label>
            <StyledInput
              type="text"
              id="name"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </StyledInputWrapper>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInputWrapper>
            <label htmlFor="email">Email</label>
            <StyledInput
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <label htmlFor="phone">Phone No</label>
            <StyledInput
              type="text"
              id="phone"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
            />
          </StyledInputWrapper>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInputWrapper>
            <label htmlFor="addressLine1">Address Line 1</label>
            <StyledInput
              type="text"
              id="addressLine1"
              name="address1"
              value={formData.address1}
              onChange={handleChange}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <label htmlFor="addressLine2">Address Line 2</label>
            <StyledInput
              type="text"
              id="addressLine2"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
            />
          </StyledInputWrapper>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInputWrapper>
            <label htmlFor="state">State</label>
            <StyledInput
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <label htmlFor="pincode">City</label>
            <StyledInput
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </StyledInputWrapper>
        </StyledInputContainer>

        {/* <StyledSubmitButton type="submit">Submit</StyledSubmitButton> */}
      </StyledForm>

      <StyledSubmitButton onClick={handleSubmit} type="submit">Submit</StyledSubmitButton>
    </StyledFormContainer>
  );
};

export default ProfileForm;