import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@mui/material/TextField";
import "bootstrap/dist/css/bootstrap.min.css";
import TableLoading from "../Loader/TableLoading";
import NavTab from "../Assets/NavTab";

import {
  ButtonContainer,
  AddButton,
  DetailsContainer,
  Container,
  MainPage,
  Text,
} from "../Styles/EventStyles";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, deleteUser, deleteAllUser } from "../features/userSlice";
import filterData from "../Utilities/filterData";
import TableUtilityLib from "../Utilities/TableUtilityLib";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { palette } from "../Styles/Palette";
const UserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleCleared, setToggleCleared] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleterow, setDelete] = useState("");

  const ITEMS_PER_PAGE = 10;
  const { users, status } = useSelector((state) => state?.user);
  const { user } = useSelector((state) => state?.auth.user);
  const isAdmin =
    user &&
    user.userid &&
    (user.userid.role === "Admin" || user.userid.role === "SuperAdmin");
  const loader = status === "loading";

  useEffect(() => {
    dispatch(fetchUser());
  }, [deleterow]);

  const handleDelete = async (row) => {
    if (
      window.confirm(`Are you sure you want to delete: ${row?.username} user `)
    ) {
      dispatch(deleteUser(row._id));
      alert("user Deleted");

      dispatch(fetchUser());

      setDelete("savjgd");
    }
  };

  const fieldsToSearch = ["firstname", "email", "role"];
  const filteredUsers = filterData(
    Array.from(users),
    searchQuery,
    fieldsToSearch
  );
  const columns = [
    {
      name: "Name",
      selector: (row) => {
        if (row.firstname && row.lastname) {
          return `${row.firstname} ${row.lastname}`.trim();
        } else if (row.username) {
          return row.username;
        } else {
          return "";
        }
      },
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Tooltip title="View">
            <IconButton onClick={() => handleView(row)}>
              {" "}
              <VisibilityIcon
                fontSize="small"
                style={{ color: palette.primary }}
              />
            </IconButton>
          </Tooltip>
          {isAdmin && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEdit(row)}>
                  <EditIcon
                    className="text-20 mr-1"
                    fontSize="small"
                    style={{ color: palette.primary }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDelete(row)}>
                  <DeleteIcon
                    className="text-20"
                    fontSize="small"
                    style={{ color: palette.primary }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

  const handleView = (record) => {
    console.log("handle vuew ", record);
    console.log("edit");
    console.log("setselected ", selectedRows);
    navigate(`/updateUser/${record._id}`);
  };
  const handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
    console.log("Selected Rows role: ", selectedRows[0]?.role);

    setSelectedRows(selectedRows);
  };

  const handleAllDelete = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows?.map(
          (r) => r.username
        )}?`
      )
    ) {
      setToggleCleared(!toggleCleared);
      console.log("inside ", selectedRows);
      dispatch(deleteAllUser(selectedRows));
      dispatch(fetchUser());
      setDelete("savjgd");
    }
  };

  const handleEdit = (record) => {
    console.log("edit", record);
    navigate(`/updateUser/${record._id}`);
  };

  const contextActions = React.useMemo(() => {
    return (
      <>
        <IconButton
          key="delete"
          onClick={() => handleAllDelete()}
          style={{ marginRight: "5px" }}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      </>
    );
  }, [users, selectedRows, toggleCleared]);

  return (
    <>
      <NavTab />
      <MainPage>
        <Text style={{ marginTop: "100px" }}>
          Explore and manage all the users
        </Text>
        <Container>
          <ButtonContainer>
            <TextField
              id="outlined-basic"
              label="Search Users"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Click here to add users">
              <AddButton onClick={() => navigate("/addUser")}> Add</AddButton>
            </Tooltip>
          </ButtonContainer>
        </Container>

        <DetailsContainer></DetailsContainer>
        {loader ? (
          <TableLoading
            rowsNum={5}
            columnsNum={5}
            columnWidths={[20, 275, 275, 275, 275]}
          />
        ) : (
          <TableUtilityLib
            columns={columns}
            data={filteredUsers}
            contextActions={contextActions}
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggleCleared}
          />
        )}
      </MainPage>
    </>
  );
};

export default UserDetails;
