import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css'
import Event from './Assets/Event';
// import EventForm from './Assets/EventForm';
import CreateEvent from './Assets/CreateEvent';
import UpdateEvent from './Assets/UpdateEvent';
import AddInstructor from './Assets/AddInstructor';
import ViewEventDetails from './Assets/ViewEventDetails'; 
import UserDetails from './Users/UserDetails';
import DemoLogin from './Login/DemoLogin';
import AddUserDetails from './Users/AddUserDetails';
import MyProfile from './My Profile/MyProfile';
import UpdateUser from './Users/UpdateUser';
import DumyTableLearn from './Assets/DumyTableLearn'
import EventNavbar from "./Assets/EventNavbar";
import PageNotFound from "./Assets/PageNotFound";
// import ProtectedRoute from './ProtectedRoute';

const App = () => {
  const { user, loading } = useSelector((state) => state.auth);

  return (
    <div>

      <BrowserRouter>
      {user?.user && (
      <EventNavbar />
      )
    }
      <Routes>
        {/* <Route path="/" element={<ProtectedRoute component={DemoLogin} />} /> */}
        {/* <Route path='/event' element={<ProtectedRoute component={Event} />}/> */}
        {user?.user ? (
          <>
        <Route path='/event' element={<Event />}></Route>
        <Route path='/userDetails' element={<UserDetails />}></Route>
        <Route path='/create' element={<CreateEvent />}></Route>
        <Route path='/update/:id' element={<UpdateEvent />}></Route>
        <Route path='/addInstructor' element={<AddInstructor />} />
        <Route path='/view/:id' element={<ViewEventDetails />} />
        <Route path='/user-details' element={<UserDetails />} />
        <Route path='/addUser' element={<AddUserDetails />} />
        <Route path='/profile' element={<MyProfile />} />
        <Route path='/updateUser/:id' element={<UpdateUser />}></Route>
        <Route path='/table' element={<DumyTableLearn />}></Route>
        </>
        ) : (
        <Route path='/' element={<DemoLogin />}  />
        )}

        <Route path='/*' element={<PageNotFound />}  />

      </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
