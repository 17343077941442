import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
// import Desktop_13 from '../Images/Desktop_13.png';
import Desktop_13 from "../Images/blue_bg_4.jpg";
import Google_logo from "../Images/Google_logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  BackgroundImage,
  LoginContainer,
  LoginFormContainer,
  LoginHeading,
  Title,
  InputGroup,
  Button,
  Form,
  Label,
  Input,
  SubTitle,
  ButtonDiv,
  Headings,
} from "../Styles/Login/LoginStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";
// import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import {
  logInUser,
  logInUserWithEmil,
} from "../features/authentication/AuthenticationSlice";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import HomePageLoader from "../Loader/HomaPageLoader";

const DemoLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // For Google login
  const [auth, setAuth] = useState(
    false || window.localStorage.getItem("auth") === "true"
  );
  const [token, setToken] = useState(null);
  const [emailToken, setEmailToken] = useState(null);

  const { loading } = useSelector((state) => state.auth);

  // to call login user after token change

  const loginUser = async (token) => {
    if (token) {
      console.log("loginuser1", token);

      try {
        const userData = await dispatch(logInUser(token));
        if (userData?.payload?.statusCode === 200) {
          navigate("/event");
        } else {
          alert(userData?.payload?.message);
        }
      } catch (error) {
        console.error("Error logging in:", error);
        alert("Error occure during login");
      }
    }
  };

  // useEffect(() => {

  //   const loginUser = async () => {
  //   if (emailToken) {
  //     try {
  //       const userData = await dispatch(logInUserWithEmil(emailToken));
  //       console.log("useeffectApiEmail" ,userData)
  //       if (userData?.payload) {
  //         console.log("undr")
  //         alert(userData.payload.message)
  //         navigate("/event");
  //       } else {
  //         alert(userData?.payload?.message)

  //       }
  //     } catch (error) {
  //       console.error("Error logging in:", error);
  //       alert("Error occure during login")
  //       // Handle error, maybe show an error message
  //     }
  //   } }
  //   loginUser();
  // }, [emailToken]);

  const handleSignIn = async (e) => {
    e.preventDefault();;

    try {
      const authData = await signInWithEmailAndPassword(
        firebase.auth(),
        email,
        password
      );
      const token = await authData.user.getIdToken();
      console.log(token);
      setEmailToken(token);
      // Save token to local storage or cookies for future requests
      axios.defaults.headers.common["Authorization"] = "Bearer " + token; // Set token in header for subsequent requests
      const userData = await dispatch(logInUserWithEmil(emailToken));
      if (userData?.payload) {
        console.log("undr");
        alert(userData.payload.message);
        navigate("/event");
      } else {
        alert(userData?.payload?.message);
      }
    } catch (error) {
      // setMessage(error.message);
    }
  };

  // const login = useGoogleLogin({
  //   onSuccess: tokenResponse => console.log(tokenResponse),
  // });

  const loginWithGoogle = async () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        const user = result.user;
        if (user) {
          user.getIdToken().then(async (token) => {
            await loginUser(token);
            setToken(token);
          });
        }
      })
      .catch((error) => {
        console.error("Error signing in with Google: ", error);
      });
  };

  if (loading) {
    return <HomePageLoader />;
  }

  return (
    <Container>
      <BackgroundImage src={Desktop_13} alt="Background" />
      <Headings>
        <Title>Welcome Back!</Title>
        <SubTitle>
          You can sign in to access with your existing profile
        </SubTitle>
      </Headings>
      <LoginContainer>
        <LoginFormContainer>
          <LoginHeading>LOGIN</LoginHeading>
          <Form onSubmit={handleSignIn}>
            <InputGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="text"
                id="username"
                name="username"
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                startAdornment={
                  <InputAdornment position="start">
                    <Lock style={{ color: "black" }} />
                  </InputAdornment>
                }
              />
            </InputGroup>
            <ButtonDiv>
              <Button type="submit">Login</Button>
            </ButtonDiv>
            {/* Google Login Button    */}
            {/* <div style={{ marginLeft:"90px" }}> */}
            {/* <GoogleLogin
  onSuccess={credentialResponse => {
  const decoded = jwtDecode(credentialResponse.credential);
  localStorage.setItem('email', decoded.email); // Store username in local storage
  navigate('/event');  
}}
  onError={() => {
    console.log('Login Failed');
  }}
/> */}

            {/* </div> */}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <button
                type="button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "20px",
                  padding: "8px 12px",
                  width: "230px",
                  fontWeight: "bold",
                }}
                onClick={loginWithGoogle}
              >
                <img
                  src={Google_logo}
                  alt="Background"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Sign in with Google{" "}
              </button>
            </div>
          </Form>

          {/* <SignupLink>Don't have an account? 
  <Link to='/signup' style={{ color: '#007bff', textDecoration: 'none' }}>Sign up</Link></SignupLink> */}
        </LoginFormContainer>
      </LoginContainer>
    </Container>
  );
};

export default DemoLogin;
