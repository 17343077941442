import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { createInstructor } from "../features/instructorSlice";

const AddInstructor = (onInstructorAdded) => {
  const dispatch = useDispatch();

  const [instructorName, setInstructorName] = useState("");

  const StyledFormContainer = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  });

  const StyledForm = styled("form")({
    width: "450px",
    height: "223px",
    padding: "20px",
    border: "2px solid #ccc",
    borderRadius: "8px",
  });

  const StyledButton = styled(Button)({
    padding: "10px 20px",
    top: "10%",
  });

  const handleAddInstructor = (e) => {
    e.preventDefault();
    console.log("first", { Name: instructorName });
    dispatch(createInstructor({ Name: instructorName }));
  };

  return (
    <StyledFormContainer container>
      <Grid>
        <StyledForm>
          <h2>Add New Instructor</h2>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Instructor Name"
                name="instructorName"
                variant="outlined"
                value={instructorName}
                sx={{ marginTop: "20px" }}
                onChange={(e) => setInstructorName(e.target.value)}
              />
            </Grid>
          </Grid>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleAddInstructor}
          >
            Add Instructor
          </StyledButton>
        </StyledForm>
      </Grid>
    </StyledFormContainer>
  );
};

export default AddInstructor;
