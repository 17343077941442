import styled from 'styled-components';
// import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import tw from "twin.macro";
import  { palette, theme } from "../Styles/Palette";


export const BackgroundImageContainer = styled.img`
    background-size: cover;
    width: 100%;
    height: 510px;
    background-attachment: fixed;
`;
export const StyledBackButton = styled.button`
  position: absolute;
  top: 100px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${palette.primary};
  left: 20px;
`;

export const NameParagraph = styled.p`
  position: absolute;
  top: 30%;
  // left: 20%;
  margin-left: 30%;
  transform: translate(-50%, -50%);
  font-size: 54px;
  font-weight: bold;
  // color: white;
  // color: ${palette.secondary};
  color: #0d318e;
`;

export const DescriptionParagraph = styled.p`
  ${tw`relative text-justify `}
  width : 90%;
  top: 75%;
  font-size: 24px;
  color: black;
`;
export const StyledContainer = styled.div`
  ${tw`flex flex-col relative text-center`} // display: flex;
  // position: relative;
  // text-align: center;
  height: 400px;
`;

export const ParaContent = styled.p`
  color: #f1f1f1;
  font-size: 17px;
`;

export const HeadContent = styled.h2`
  margin-bottom: 10px;
  font-size: 25px;
`;
export const Container = styled.div`
  position: relative;
`;

export const DetailsHeader = styled.div`
  width: 350px;
  height: 50px;
  background: #0d318e;
  border: 1px solid #000;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 23px;
  transition: all 0.3s ease-out;

  &:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11), 0 24px 46px rgba(0, 0, 0, 0.48);
  }
`;

export const DetailsContent = styled.div`
  width: 350px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 7px;
  transition: all 0.3s ease-out;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);

  &:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11), 0 24px 46px rgba(0, 0, 0, 0.48);
  }
`;
export const StyledStrong = styled.strong`
  color: #0d318e;
`;

export const MainStyledComponent = styled.div`
  position: relative;
  z-index: 1;
  // margin-top: -100px;
  margin-bottom: 50px;
`;
export const MainStyledDiv =  styled.div`
display: flex;
justify-content: center;
`;

export const EventDetailDiv = styled.div`
margin-top: 40px;
text-align: center;
padding: 20px;
background: #f0f0f0;
border-radius: 10px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;
export const StyledHeading = styled.h2`
font-size: 35px;
color: #0D318E; 
font-weight: bold;
margin-bottom: 20px;
`;

export const StyledText = styled.p`
font-size: 18px;
margin-bottom: 15px;
`;

export const ContainerDetail = styled.div`
display: flex;
align-items: center;
justify-content:space-evenly;

`;
// export const StyledDiv = styled.div`
//   width: 350px;
//   height: 200px;
//   background: ${props => props.isHovered ? "#0D318E" : "#FF9900"};
//   margin-right: 10px;
//   display: flex;
//   flex-direction: coumn;
//   justify-content: center;
//   align-items: center;
//   color: ${props => props.isHovered ? "#FFF" : "white"};
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
//   transition: all 0.3s ease;

//   &:hover {
//     cursor: pointer;
//   }
// `;


export const StyledDiv = styled.div`
  width: 350px;
  height: 200px;
//   border-radius: 10px 60px;
  background: ${props => props.isHovered ? "#0D318E" : "#036ACB"};
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.isHovered ? "#FFF" : "white"};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #0D318E;
    color: #FFF;
  }
`;