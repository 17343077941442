import styled from "styled-components";
import tw from "twin.macro";
import { Grid } from '@mui/material';

export const MainDiv = styled.div`
${tw`mt-16 mx-auto px-5 
mx-auto rounded-lg`}
`;

export const Head =styled.div`
${tw`relative flex items-center justify-center mt-20`}
`;

export const ArrowContainer = styled.div`
${tw`absolute left-0`}
`;

export const Form = styled.div`
${tw`border border-gray-700 rounded-md`}
`;

export const StyledContainer = styled.div`
${tw `relative w-3/4 mx-auto p-5 mx-auto p-4`} 
overflow-x:"hidden",
`;

export const StyledHeading = styled.h2`
${tw`text-center`}`;

export const AddFieldDiv = styled.div`
 ${tw `grid justify-items-end`}
`;

export const AddFieldButton = styled.button`
  ${tw `sm:ml-2 bg-gray-200 text-black border border-gray-200  px-6 py-2 text-base rounded cursor-pointer transition-colors duration-300 hover:bg-gray-300`}
  
`;

export const ButtonDiv = styled.div`
${tw`grid justify-items-center`}
`;

export const AddEventButton = styled.button`
  ${tw ` bg-blue-500 text-white border-none px-6 py-2 rounded cursor-pointer transition-colors duration-300 hover:bg-blue-700 m-4`}
`;

export const TextFieldContainer = styled.div`
  ${`2xl:text-2xl md:text-xs `}
`;
export const ResponsiveGrid = styled.div`
${tw `flex flex-col lg:flex-row`}
`;

export const ResponsiveGridItem = styled(Grid)`
${tw `mx-0.5`}
`;
