import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@mui/material/TextField";
import "bootstrap/dist/css/bootstrap.min.css";
import SearchIcon from "@mui/icons-material/Search";
import ExcelTable from "./ExcelTable";
import CsvDownload from "./CsvDownload";
import EventNavbar from "./EventNavbar";
import filterData from "../Utilities/filterData";
import {
  SearchBar,
  MainPage,
  Container,
  DetailsContainer,
  ButtonContainer,
  AddButton,
  Button,
  ButtonDownloadCSV,
  Text,
} from "../Styles/EventStyles";
import NavTab from "./NavTab";
import TableLoading from "../Loader/TableLoading";

import { useDispatch, useSelector } from "react-redux";
import { fetchEvent, deleteEvent } from "../features/eventSlice";
import TableUtilityLib from "../Utilities/TableUtilityLib";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { palette } from "../Styles/Palette";

// Helper function to generate CSV content
const generateCSV = (headers, data) => {
  // Prepare CSV content with headers and data rows
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    [
      headers.join(','), // First row is the headers
      ...data.map((item) => {
        return headers
          .map((header) => {
            const value = item[header] ?? ''; // Ensure non-null values
            // If there's a comma, wrap with double quotes
            return value.includes(',') ? `"${value.replace(/"/g, '""')}"` : value;
          })
          .join(','); // Join row values with commas
      }),
    ].join('\n'); // Join rows with newline characters

  return csvContent;
};

// Function to trigger CSV download
const downloadCSV = (csvContent, filename) => {
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename); // Set the file name for download
  document.body.appendChild(link);
  link.click(); // Start the download
  document.body.removeChild(link); // Clean up after download
};


const Event = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const { user } = useSelector((state) => state?.auth.user);
  const { events, status } = useSelector((state) => state.app);

  const [childValue, setChildValue] = useState("");

  const handleChildValueChange = (value) => {
    setChildValue(value);
  };

  const isAdmin =
    user &&
    user.userid &&
    (user.userid.role === "Admin" || user.userid.role === "SuperAdmin");
  const loader = status === "loading";

  useEffect(() => {
    dispatch(fetchEvent());
  }, [selectedRows, childValue]);

  const fieldsToSearch = ["EventName", "InstName", "CourseType", "EventDesp"];

  const filteredUsers = Array.isArray(events)
    ? filterData(events, searchQuery, fieldsToSearch)
    : [];

  const handleDelete = (row) => {
    if (
      window.confirm(
        `Are you sure you want to delete: ${row?.EventName}  Event  `
      )
    ) {
      dispatch(deleteEvent(row._id));
      setSelectedRows(row);
    }
  };

  const handleEdit = (row) => {
    console.log(row);
    navigate(`/update/${row._id}`);
    setSelectedRows(row);
  };
  
  const generateColumns = (labels, keys) => {
    const validKeys = keys.filter((key, index) => labels[index]);

    return validKeys.map((key, index) => ({
      name: labels[index],
      selector: (row) => row[key],
      sortable: true,
    }));
  };

  const headers = [
    'EventId',
    'EventName',
    'OrgId',
    'OrgName',
    'EventDesp',
    'EventSummary',
    'SchoolId',
    'SDate',
    'EDate',
    'STime',
    'ETime',
    'tagInput',
    'CourseFees',
    'SponsorName',
    'SponsorId',
    'ClassName',
    'ClassId',
    'CoursePrototype',
    'ExpireAfter',
    'State1ElectiveCore',
    'State2ElectiveCore',
    'Duration',
    'AffiliationCategory',
    'Criteria',
    'Credits',
    'CourseType',
    'Delivery',
    'InstCode',
    'InstName',
    'CourseAffiliation',
    'EventAccess',
    'SponsorLink',
    'ExamLink',
    'MeetLink',
    'EvaluLink',
    'MaterialLink',
    'OutlookCal',
    'OptOut',
    'Certificate',
    'CancelPolicy',
  ];

  const handleDownloadCSV = () => {
    const csvContent = generateCSV(headers, events); // Generate CSV content with headers and data
    downloadCSV(csvContent, 'events.csv'); // Start the download with a filename
  };

  const labels = [
    "Event Title",
    "Start Date",
    "Start Time",
    "End Date",
    "End Time",
    "Duration",
    // 'Course Type',
    // 'Delivery Method',
    // 'Criteria',
    // 'Instructor Code',
    "Instructor Name",
    // 'Event Description',
    "Meet Link",
    "Actions",
  ];

  const keys = [
    "EventName",
    "SDate",
    "STime",
    "EDate",
    "ETime",
    "Duration",
    // 'CourseType',
    // 'Delivery',
    // 'Criteria',
    // 'InstCode',
    "InstName",
    // 'EventDesp',
    "MeetLink",
    // Assuming 'Actions' does not have a corresponding key
  ];

  const columns = generateColumns(labels, keys);

  columns.push({
    name: "Actions",
    cell: (row) => (
      <div>
        <Tooltip title="View">
          <IconButton onClick={() => handleView(row)}>
            {" "}
            <VisibilityIcon
              fontSize="small"
              style={{ color: palette.primary }}
            />
          </IconButton>
        </Tooltip>
        {isAdmin && (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEdit(row)}>
                <EditIcon
                  className="text-20 mr-1"
                  fontSize="small"
                  style={{ color: palette.primary }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => handleDelete(row)}>
                {" "}
                <DeleteIcon
                  className="text-20"
                  fontSize="small"
                  style={{ color: palette.primary }}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    ),
    ignoreRowClick: true,

    button: true,
    width: "120px",
  });

  const handleView = (record) => {
    console.log("handle vuew ", record);
    console.log("edit");
    navigate(`/view/${record._id}`);
  };

  const handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
  };

  return (
    <>
      <NavTab />
      <MainPage>
        <Text style={{ marginTop: "100px" }}>
          Explore and manage upcoming events with ease.
        </Text>
        <Container>
          <ButtonContainer>
            <TextField
              id="outlined-basic"
              label="Search Events"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button>
              <Tooltip title="Click here to add event">
                <AddButton onClick={() => navigate("/create")}> Add</AddButton>
              </Tooltip>
              <ExcelTable onChildValueChange={handleChildValueChange} />
              <Tooltip title="Click here to download">
                <AddButton onClick={handleDownloadCSV}> Export</AddButton>
              </Tooltip>
            </Button>
          </ButtonContainer>
        </Container>

        {loader ? (
          <TableLoading
            rowsNum={5}
            columnsNum={5}
            columnWidths={[20, 275, 275, 275, 275]}
          />
        ) : (
          <TableUtilityLib
            columns={columns}
            data={Array.from(filteredUsers)}
            onSelectedRowsChange={handleChange}
          />
        )}
      </MainPage>
    </>
  );
};

export default Event;
