import styled from "styled-components";
import tw from "twin.macro";

export const MainPage = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border: 2px solid #ccc;
border-radius: 8px;
padding: 20px;
width: 50%;
margin-top: 10px;
`;

export const ArrowContainer = styled.div`
${tw`absolute top-0 left-0`}
`;

export const TitleContainer =styled.div`
${tw`w-[50%] relative flex justify-center mt-20`}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

`;

export const StyledDiv = styled.div`
  width: 60%;
  `;

export const Title = styled.h2`
  // margin-top: 100px;
  text-align: center;
`;

export const Contentwrapper = styled.div`

  margin-top: 40px;
`;