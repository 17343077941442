import Event_meet from "../Images/Event_main.jpg";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Tooltip } from "@mui/material";
import {
  Container,
  DetailsHeader,
  DetailsContent,
  StyledStrong,
  StyledDiv,
  HeadContent,
  StyledContainer,
  ContainerDetail,
  StyledText,
  StyledHeading,
  EventDetailDiv,
  MainStyledDiv,
  MainStyledComponent,
  StyledBackButton,
  BackgroundImageContainer,
  DescriptionParagraph,
  NameParagraph,
  ParaContent,
} from "../Styles/ViewEventDetailsStyles";
import EventNavbar from "./EventNavbar";
import { useDispatch, useSelector } from "react-redux";
import { viewEventDetails } from "../features/eventSlice";

const ViewEventDetails = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const { id } = useParams();
  console.log("jsfg\fbsdg", id);
  const dispatch = useDispatch();

  const { events, status } = useSelector((state) => state.app); // Assuming your event reducer is named 'event'



  useEffect(() => {
    // Fetch event details when component mounts
    dispatch(viewEventDetails(id));
  }, [, id]);

  // Hover Effect
  const handleMouseEnter = () => {
    // Handle mouse enter event
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event
  };
  //Go back button function
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <grid>
      <EventNavbar />
      <StyledContainer>
        {/* <BackgroundImageContainer alt="Event" src={Event_meet} /> */}
        {/* <BackgroundImageContainer alt="Event" src="https://img.freepik.com/free-vector/flat-design-business-planning-concept_23-2149151729.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1713139200&semt=ais"/> */}

        <Tooltip title="Back">
          <StyledBackButton onClick={handleGoBack} aria-label="go back">
            <ArrowBackIosIcon />
          </StyledBackButton>
        </Tooltip>
        <div
          style={{
            display: "flex",
            flexDirection: "col",
            alignItems: "center",
          }}
        >
          <NameParagraph>{events?.EventName}</NameParagraph>
          <DescriptionParagraph>{events?.EventDesp}</DescriptionParagraph>
        </div>
      </StyledContainer>

      <MainStyledComponent>
        <MainStyledDiv>
          {/* div 1 */}
          <StyledDiv>
            <CalendarTodayIcon
              style={{ fontSize: 50, color: "white", marginBottom: "10px" }}
            />
            <HeadContent>Date and Time</HeadContent>
            <ParaContent>
              {events?.SDate} {events?.STime}
            </ParaContent>
          </StyledDiv>

          {/* div 2 */}
          <StyledDiv>
            <AccessTimeIcon
              style={{ fontSize: 50, color: "white", marginBottom: "10px" }}
            />
            <HeadContent>Duration</HeadContent>
            <ParaContent>{events?.Duration}</ParaContent>
          </StyledDiv>

          {/* div 3 */}
          {/* <div style={{width: "350px", height: "200px", background: "#FF9900", marginRight: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)'  }}>
    <DescriptionIcon style={{ fontSize: 50, color: 'white', marginBottom: '10px' }} />
    <h2 style={{ marginBottom: "10px", textAlign:"center", fontSize:'25px' }}>Course Delivery Method</h2>
    <p style={{color:'#F1F1F1', fontSize:'17px'}}>{eventDetails?.Delivery}</p>
</div> */}
          {/* 
 <StyledDiv isHovered={isHovered} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <DescriptionIcon style={{ fontSize: 50, color: 'white', marginBottom: '10px' }}  />
      <h2 style={{ marginBottom: "10px", textAlign: "center", fontSize: '25px' }}>Course Delivery Method</h2>
      <p style={{ color: isHovered ? '#F1F1F1' : '#FFFFFF', fontSize: '17px' }}>{eventDetails?.Delivery}</p>
    </StyledDiv> */}

          <StyledDiv
            isHovered={false}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <DescriptionIcon
              style={{ fontSize: 50, color: "white", marginBottom: "10px" }}
            />
            <HeadContent style={{ textAlign: "center" }}>
              Course Delivery Method
            </HeadContent>
            <ParaContent>{events?.Delivery}</ParaContent>
          </StyledDiv>
        </MainStyledDiv>

        {/* event detail text */}
        <EventDetailDiv>
          <StyledHeading>Join the Event</StyledHeading>
          <StyledText>
            Additional details about the event can be found below.
          </StyledText>
          <StyledText>
            Feel free to reach out to us if you have any questions or need
            further assistance.
          </StyledText>
          <StyledText>We look forward to your participation!</StyledText>
        </EventDetailDiv>

        <ContainerDetail>
          <Container>
            <DetailsHeader>
              <p>Session Details</p>
            </DetailsHeader>
            <DetailsContent>
              <p>
                <StyledStrong>Start Date:</StyledStrong> {events?.SDate}
              </p>
              <p>
                <StyledStrong>Start Time:</StyledStrong> {events?.STime}
              </p>
              <p>
                <StyledStrong>End Date:</StyledStrong> {events?.EDate}
              </p>
              <p>
                <StyledStrong>End Time:</StyledStrong> {events?.ETime}
              </p>
              <p>
                <StyledStrong>Criteria:</StyledStrong> {events?.Criteria}
              </p>
              <p>
                <StyledStrong>Affiliation Category:</StyledStrong>{" "}
                {events?.Role}
              </p>
              <p>
                <StyledStrong>Course Type:</StyledStrong> {events?.CourseType}
              </p>
              <p>
                <StyledStrong>Event Accessibility:</StyledStrong>{" "}
                {events?.EventAccess}
              </p>
              {/* <p>
                <StyledStrong>Meet Link:</StyledStrong> {events?.MeetLink}
              </p> */}
            </DetailsContent>
          </Container>
          <Container style={{ marginTop: "20px" }}>
            <DetailsHeader>
              <p>Course Details</p>
            </DetailsHeader>
            <DetailsContent
              style={{ boxShadow: " 0px 10px 10px rgba(0, 0, 0, 0.2)" }}
            >
              {/* <p><StyledStrong>Course ID:</StyledStrong> {events?.CourseId}</p> */}
              <p>
                <StyledStrong>Event Name:</StyledStrong> {events?.EventName}
              </p>
              {/* <p><StyledStrong>Course Description:</StyledStrong> {events?.CourseDesp}</p> */}
              {/* <p><strong>Alternate Course ID:</strong> {eventDetails?.AltCourseId}</p>
        <p><strong>Alternate Name:</strong> {eventDetails?.AltCourseName}</p> */}
              <p>
                <StyledStrong>Instructor ID:</StyledStrong> {events?.InstCode}
              </p>
              <p>
                <StyledStrong>Instructor Name:</StyledStrong> {events?.InstName}
              </p>
              <p>
                <StyledStrong>Course Affiliation:</StyledStrong>{" "}
                {events?.CourseAffilation}
              </p>
              <p>
                <StyledStrong>Certificate:</StyledStrong> {events?.Certificate}
              </p>
              <p>
                <StyledStrong>Exam Required:</StyledStrong> {events?.Exam}
              </p>
              <p>
                <StyledStrong>Material Link:</StyledStrong>{" "}
                {events?.MaterialLink}
              </p>
              <p>
                <StyledStrong>Evaluation Link:</StyledStrong>{" "}
                {events?.EvaluLink}
              </p>
            </DetailsContent>
          </Container>

          <Container style={{ marginTop: "-55px" }}>
            <DetailsHeader>
              <p>Organization Details</p>
            </DetailsHeader>
            <DetailsContent>
              <p>
                <StyledStrong>Organization ID :</StyledStrong> {events?.OrgId}
              </p>
              <p>
                <StyledStrong>Organization Name:</StyledStrong>{" "}
                {events?.OrgName}
              </p>
              <p>
                <StyledStrong>School ID Number:</StyledStrong>{" "}
                {events?.SchoolId}
              </p>
              <p>
                <StyledStrong>Sponsor ID:</StyledStrong> {events?.SponsorId}
              </p>
              <p>
                <StyledStrong>Sponsor Name:</StyledStrong> {events?.SponsorName}
              </p>
            </DetailsContent>
          </Container>
        </ContainerDetail>
      </MainStyledComponent>
    </grid>
  );
};

export default ViewEventDetails;
