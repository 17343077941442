import {configureStore} from '@reduxjs/toolkit';
import eventDetails from '../features/eventSlice';
import userDetails from '../features/userSlice';
import instructorDetails from '../features/instructorSlice';
import authDetails from '../features/authentication/AuthenticationSlice';


export const store = configureStore ({
    reducer:{
        app: eventDetails,
        user: userDetails,
        inst: instructorDetails,
        auth : authDetails
    },
});
 