import styled from "styled-components";
import tw from "twin.macro";
import  { palette, theme } from "../Palette";

export const Container = styled.div`
${tw `flex w-screen h-screen items-center justify-center sm:justify-between flex-col gap-10 sm:flex-row sm:gap-14 px-12`}
`;

export const BackgroundImage = styled.img`
${tw `fixed top-0 left-0 -z-1 w-screen h-screen object-cover`}
`;

export const LoginContainer = styled. div`
${tw `w-full sm:w-1/2 lg:w-1/3`}
`;

export const LoginFormContainer = styled.div`
  ${tw`sm:p-8 rounded-lg p-4 `}
  background-color: rgba(255, 255, 255, 0.9);
`;

export const LoginHeading = styled.h2`
${tw `text-center mt-4`}
`;
export const Form = styled.form`
// ${tw `mb-5`}
`;

export const InputGroup = styled.div`
${tw `mb-5`}
`;

export const Label = styled.label`
${tw `block mt-8 text-gray-600 text-lg`}
`;

export const Input = styled.input`
${tw `w-full py-3 px-4 border-b border-slate-600 outline-0`}
    background-color: #E5F4FF; 
`;

export const ButtonDiv = styled.div`
${tw`flex justify-around`}
`;

export const Button = styled.button`
${tw`px-24 py-2 bg-blue-500 text-white rounded-3xl cursor-pointer transition duration-300 hover:bg-blue-700`}
background: ${palette.primary};
`;

export const Headings = styled.div`
${tw`flex flex-col gap-4 sm:gap-6 lg:gap-8 items-center`}
`;

export const Title = styled.h1`
${tw `text-white lg:text-4xl text-center`}
`;

export const SubTitle = styled.h4`
${tw `text-white text-center`}
`;