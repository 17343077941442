import styled from "styled-components";
import tw from "twin.macro";
import { IconButton } from '@mui/material';
import  { palette, theme } from "../Styles/Palette";

export const MainPage = styled.div`
${tw`relative p-14`}
`;

// export const Text = styled.p`
// ${tw``}
// `;

export const SearchBar = styled.div`
// ${tw `flex items-center float-right mt-3`}

//     input {
//       ${tw `w-64 h-9 mr-8 px-2 rounded border-b border-gray-300 outline-none`}
//     }
`;

export const Text = styled.p`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider leading-relaxed text-justify md:text-left`}
  // color: ${palette.secondary};
`;

export const SearchBarComponent = styled.div`
${tw `flex items-center float-right `}

    input {
      ${tw `w-64 h-7 mr-8 px-2 rounded border-b border-gray-300 outline-none`}
    }
`;
export const Container = styled.div`
${tw `flex justify-between items-center`}
`;

export const DetailsContainer = styled.div`
    // margin-left: 15px;
    p {
        margin: 5px 0;
    }
`;

export const ButtonContainer = styled.div`
${tw `pb-8 pt-4 flex flex-col-reverse justify-between w-full sm:flex-row`}
`;

export const Button = styled.div`
${tw`flex flex-col sm:flex-row justify-center gap-2 items-stretch flex-wrap my-4`}
`; 

export const AddButton = styled.button`
${tw ` text-white px-4 py-2 rounded h-10 ml-2`}
background: ${palette.primary};
`;

export const StyledTable = styled.div`
${tw `h-screen justify-center items-center`}
// border: 1px solid white;
border-radius: 5px;
// background-color: #f3f4f6;
//#f9fafb

`;
export const StyledTableSection = styled.div`
${tw `bg-white h-96 mt-9 rounded-md  mx-3`}
`;

export const Table = styled.table`
${tw `border-b-2 border-gray-300 w-full`}
// width: 98%;
// margin-left: 12px;
margin-top: 10px;

tbody tr:nth-child(odd) {
  background: ${palette.evenrow_bg};
}

tbody tr:nth-child(even) {
  background-color: white; /* Set the background color for even rows */
}
tbody tr:hover {
  background: ${palette.Tablerow_hover};/* Set the background color on hover */
}
`;

export const TheadRow = styled.tr`
${tw `h-12 border-t-2 border-b-2 `}
border: ${palette.primary};
`;

export const StyledLabel = styled.label`
 ${tw ` text-white rounded h-10 mx-2`}
 background: ${palette.secondary};
 border: ${palette.secondary};
 &:hover {
  background: ${palette.secondary};
    color: white; 
  }
// margin-top: 31px;
`;

export const StyledTableRow = styled.tr`
${tw `p-2 md:p-4 h-16`}
`;

export const StyledTh = styled.th`
  ${tw `lg:w-36 sm:text-xs lg:text-base w-3/4 `}
  margin-left:20px;
  background: ${palette.primary};
  color: white;
  // lg:mr-16 sm:mr-4
   
`;

export const StyledTd = styled.td`
${tw `lg:w-32 sm:text-xs lg:text-base `}
white-space: nowrap;

`;
//Download CSV button
export const ButtonDownloadCSV = styled.button`
// background: ${palette.primary};
// color: white;
// margin-bottom: -20px;
`;

// Pagination CSS
export const PaginationDiv = styled.div`
${tw `mt-5 flex justify-center items-center`}
`;

// export const StyledIconButton = styled(IconButton)`
// color: ${palette.TableIconHover};
// `;