import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../Styles/Palette";

import {
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  MainPage,
  Form,
  Title,
  ArrowContainer,
  TitleContainer,
} from "../Styles/UserStyles/AddUserStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, fetchUserDetails } from "../features/userSlice";

const UpdateUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { users, status } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [UserData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    // password: "",
  });
  const {firstname, lastname, email, role, password } = UserData;
  const handleChange = (e) => {
    setUserData({ ...UserData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(fetchUserDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setUserData({
      _id: users?._id,
      firstname: users?.firstname,
      lastname: users?.lastname,
      email: users?.email,
      role: users?.role,
      password: users?.password,
    });
  }, [users, id, dispatch]);

  const UpdateUser = (e) => {
    e.preventDefault();
    dispatch(updateUser(UserData));
    navigate("/UserDetails");
  };

  return (
    <Form>
      <TitleContainer>
        <ArrowContainer>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{ color: palette.primary }} />
          </IconButton>
        </ArrowContainer>
        <Title>Update User Details</Title>
      </TitleContainer>
      <MainPage>
        <div style={{ width: "60%" }}>
          <div style={{ marginTop: "40px" }}>
          <Grid sx={{ mb: 3 }}>
              <TextField
                label="First Name"
                variant="outlined"
                name="firstname"
                value={UserData.firstname}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

            <Grid sx={{ mb: 3 }}>
              <TextField
                label="Last Name"
                variant="outlined"
                name="lastname"
                value={UserData.lastname}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

            <Grid sx={{ mb: 3 }}>
              <TextField
                label="Email Address"
                variant="outlined"
                name="email"
                value={UserData.email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>

             <Grid sx={{ mb: 3 }}>

              <TextField
                label="Role"
                select
                name="role"
                value={UserData.role}
                onChange={handleChange}
                displayEmpty
                fullWidth
                required
              >
                <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                <MenuItem value="Instructor">Instructor</MenuItem>
                <MenuItem value="Learner">Learner</MenuItem>
              </TextField>

            </Grid>

            {/* <Grid sx={{ mb: 3 }}>
              <TextField
                label="Password"
                variant="outlined"
                name="password"
                value={UserData.password}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid> */}

            <Grid sx={{ mb: 3, ml: 15 }}>
              <button
                type="submit"
                className="btn btn-primary"
                fullWidth
                onClick={UpdateUser}
              >
                Save Changes
              </button>
            </Grid>
          </div>
        </div>
      </MainPage>
    </Form>
  );
};

export default UpdateUser;
